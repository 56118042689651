<template>
  <div v-if="!loading" class="mx-auto">
    <v-card elevation="0" class="rounded-0">
      <v-card-text>
        <div class="header pa-4" @click="goToPendingWorkList" v-if="worktimePendingCount > 0">
          <h1>アウトしていない勤怠が {{ worktimePendingCount }} 件あります。</h1>
        </div>
        <v-card v-if="worktime.task_details" class="mt-5 bg-blk">
          <v-row class="confirm-sec">
            <v-col cols="12">
              <div class="wt-id">WT-{{ worktime.id }}</div>
              <strong>
                {{ worktime.task_details.customer.name }} -
                {{ worktime.task_details.site.name }}
              </strong>
              <div class="service-name">
                {{ worktime.task_details.service.name }}
              </div>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="3" class="text-right">月日：</v-col>
                <v-col cols="9">{{ formatDate(worktime.task_details.date) }}</v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="3" class="text-right">タイプ：</v-col>
                <v-col cols="9">{{ getClockType(worktime.type_id) }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <WorktimeCreateForm :task="task" :data="data" :is-edit="true" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import WorktimeCreateForm from "@/components/layout/worktime/form/WorktimeCreateForm";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  name: "WorktimeEdit",
  components: { WorktimeCreateForm },
  created() {
    this.loading = false;
    this.$store.dispatch("WORKTIME_GET", this.$route.params.id);
    this.$store.dispatch("COUNT_PENDING_WORKTIME");
  },
  watch: {
    worktime() {
      this.data = this.formatWorkTimeData();
    },
  },
  computed: {
    ...mapGetters(["worktime", "worktimePendingCount"]),
  },
  data() {
    return {
      loading: false,
      date: null,
      modal: false,
      task: 1,
      data: {
        type: {},
        clock_in: null,
        clock_out: null,
        tag: null,
        memo: null,
        resttime: [],
        task: {},
      },
      types: [
        { value: 0, text: "現場勤務（リ）" },
        { value: 1, text: "現場勤務（個）" },
        { value: 2, text: "運転・調整" },
        { value: 3, text: "参加" },
        { value: 4, text: "タスク" },
        { value: 5, text: "その他" },
      ],
    };
  },
  methods: {
    formatWorkTimeData() {
      let worktime = JSON.parse(JSON.stringify(this.worktime));
      this.data.id = worktime.id;
      this.data.clock_in = worktime.clock_in;
      this.data.clock_out = worktime.clock_out;
      this.data.memo = worktime.memo;
      this.data.type = worktime.type_id;
      this.data.task.id = worktime.task_details.id;

      this.data.tag = worktime.tags.map((tag) => {
        return tag.id;
      });

      if (worktime.rest_hours_day != "0:0") {
        this.data.resttime.push({
          type: { text: "昼休憩", value: 0 },
          minutes: this.formatRestMinutes(worktime.rest_hours_day),
        });
      }

      if (worktime.rest_hours_night != "0:0") {
        this.data.resttime.push({
          type: { text: "夜休憩", value: 1 },
          minutes: this.formatRestMinutes(worktime.rest_hours_night),
        });
      }

      return this.data;
    },
    getClockType(type_id) {
      return this.types.find((e) => e.value === type_id).text || null;
    },
    changeDate() {
      this.$refs.dialog.save(this.date);
    },
    changeTask() {},
    formatDate(date) {
      var day = this.getDay(date);
      return dayjs(date).format("YYYY年M月D日") + "（" + day + "）";
    },
    getDay(date) {
      var days = [
        "日", //Sun
        "月", //Mon
        "火", //Tue
        "水", //Wed
        "木", //Thu
        "金", //Fri
        "土", //Sat
      ];
      var d = new Date(date);
      var dayName = days[d.getDay()];
      return dayName;
    },
    formatRestMinutes(time) {
      var t = time.split(":");
      var minutes = +t[0] * 60 + +t[1];
      return minutes;
    },
    goToPendingWorkList() {
      this.$router.push({
        name: "worktime",
        query: { pending: 1 },
      });
    },
  },
};
</script>

<style scoped>
.header {
  background: #ff8585;
  cursor: pointer;
}
.header h1 {
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 1;
}
.title {
  font-size: 14px !important;
}
.theme--light.v-input,
.theme--light.v-input input {
  text-align: center;
  font-size: 12px;
}
.bg-blk {
  background: #fffff0;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  box-shadow: 0px 0px !important;
}
.confirm-sec {
  padding: 10px;
  font-size: 14px;
}
.confirm-sec .wt-id {
  font-size: 12px;
  color: #828282;
}
.confirm-sec strong {
  font-size: 15px;
}
</style>
