<template>
  <WorktimeEdit />
</template>

<script>
import WorktimeEdit from "@/components/worktime/WorktimeEdit";
export default {
  name: "Worktime",
  components: { WorktimeEdit }
};
</script>

<style scoped></style>
